import React from "react";

import { IndexRedirect, IndexRoute, Redirect } from "react-router";
import { routerActions } from "react-router-redux";
import { UserAuthWrapper } from "redux-auth-wrapper";
import { t } from "ttag";
import { Route } from "metabase/hoc/Title";
import {
  loadCurrentUser,
  loadCurrentUserVip,
  loadCurrentUserVipDataApi,
} from "metabase/redux/user";
import MetabaseSettings from "metabase/lib/settings";
import { Unauthorized } from "metabase/containers/ErrorPages";
import getAccountRoutes from "metabase/account/routes";
import getAdminRoutes from "metabase/admin/routes";
import getCollectionTimelineRoutes from "metabase/timelines/collections/routes";
import { ModalRoute } from "metabase/hoc/ModalRoute";
import { trackPageView } from "metabase/lib/analytics";
import { getAdminPaths } from "metabase/admin/app/selectors";
import { loadConfig } from "metabase/redux/config";
import { isProduction } from "metabase/env";
import LazyLoad from "./routesLazyLoad";

const MetabaseIsSetup = UserAuthWrapper({
  predicate: authData => (isProduction ? !authData.hasSetupToken : true),
  failureRedirectPath: "/setup",
  authSelector: state => ({ hasUserSetup: MetabaseSettings.hasUserSetup() }), // HACK
  wrapperDisplayName: "MetabaseIsSetup",
  allowRedirectBack: false,
  redirectAction: routerActions.replace,
});

const UserIsAuthenticated = UserAuthWrapper({
  failureRedirectPath: () => {
    const from = localStorage.getItem("lastGTag");
    return `/auth/login?${from ? "from=" + from : ""}`;
  },
  authSelector: state => state.currentUser,
  wrapperDisplayName: "UserIsAuthenticated",
  redirectAction: routerActions.replace,
});

const UserIsAdmin = UserAuthWrapper({
  predicate: currentUser => currentUser && currentUser.is_superuser,
  failureRedirectPath: "/unauthorized",
  authSelector: state => state.currentUser,
  allowRedirectBack: false,
  wrapperDisplayName: "UserIsAdmin",
  redirectAction: routerActions.replace,
});

const UserIsNotAuthenticated = UserAuthWrapper({
  predicate: currentUser => !currentUser,
  failureRedirectPath: "/",
  authSelector: state => state.currentUser,
  allowRedirectBack: false,
  wrapperDisplayName: "UserIsNotAuthenticated",
  redirectAction: routerActions.replace,
});

const UserCanAccessSettings = UserAuthWrapper({
  predicate: adminItems => adminItems?.length > 0,
  failureRedirectPath: "/unauthorized",
  authSelector: getAdminPaths,
  allowRedirectBack: false,
  wrapperDisplayName: "UserCanAccessSettings",
  redirectAction: routerActions.replace,
});

const IsAuthenticated = MetabaseIsSetup(
  UserIsAuthenticated(({ children }) => children),
);
const IsAdmin = MetabaseIsSetup(
  UserIsAuthenticated(UserIsAdmin(({ children }) => children)),
);

const IsNotAuthenticated = MetabaseIsSetup(
  UserIsNotAuthenticated(({ children }) => children),
);

const CanAccessSettings = MetabaseIsSetup(
  UserIsAuthenticated(UserCanAccessSettings(({ children }) => children)),
);

export const getRoutes = store => (
  <Route
    title={t`Telegraph Bay`}
    component={LazyLoad.App}
    onEnter={nextState => {
      trackPageView(nextState.location.pathname, "Enter");
    }}
  >
    {/* SETUP */}
    <Route
      path="/setup"
      component={LazyLoad.SetupApp}
      onEnter={(nextState, replace) => {
        if (MetabaseSettings.hasUserSetup()) {
          replace("/");
        }
      }}
    />

    {/* PUBLICLY SHARED LINKS */}
    <Route path="public">
      <Route
        title={t`Public Question`}
        path="question/:uuid"
        component={LazyLoad.PublicQuestion}
      />
      <Route
        title={t`Public Dashboard`}
        path="dashboard/:uuid"
        component={LazyLoad.PublicDashboard}
      />
    </Route>

    {/* APP */}
    <Route
      onEnter={async (nextState, replace, done) => {
        store.dispatch(loadConfig());
        const result = await store.dispatch(loadCurrentUser());
        console.log("current user result", result)
        // if (store.getState().currentUser) {
        //   store.dispatch(loadCurrentUserVip());
        //   store.dispatch(loadCurrentUserVipDataApi());
        // }
        done();
      }}
      onChange={(prevState, nextState, replace) => {
        let { pathname: prevPathname } = prevState.location;
        if (!prevPathname.startsWith("/")) {
          prevPathname = "/" + prevPathname;
        }
        let { pathname: nextPathname } = nextState.location;
        if (!nextPathname.startsWith("/")) {
          nextPathname = "/" + nextPathname;
        }
        if (nextPathname.startsWith("/admin")) {
          return
        }

        if (
          prevPathname.startsWith("/ab") &&
          !nextPathname.startsWith("/ab") && !nextPathname.startsWith("/auth/login")
        ) {
          // nextState.location.pathname = `/ab${nextPathname}`
          // replace(nextState.location)
        }
      }}
    >
      {/* ----------- AB Analytics 👆 --------- */}

      {/* AUTH */}
      <Route path="/auth">
        <IndexRedirect to="/auth/login" />
        <Route component={IsNotAuthenticated}>
          <Route path="login" title={t`Login`} component={LazyLoad.LoginApp} />
          <Route
            path="login/:provider"
            title={t`Login`}
            component={LazyLoad.LoginApp}
          />
        </Route>
        <Route path="logout" component={LazyLoad.LogoutApp} />
        <Route path="forgot_password" component={LazyLoad.ForgotPasswordApp} />
        <Route
          path="reset_password/:token"
          component={LazyLoad.ResetPasswordApp}
        />
      </Route>

      {/* MAIN */}
      <Route component={IsAuthenticated}>
        <Route path="/" component={LazyLoad.About}>
          {/*<IndexRedirect to="/about" />*/}
          <ModalRoute
            title={t`Login`}
            path="loginModal"
            modal={LazyLoad.LoginModal}
            modalProps={{ className: "loginModalRoot" }}
          />
        </Route>

        <Route
          title={t`Dashboard`}
          path="/@:name/:dashboardName"
          component={LazyLoad.DashboardApp}
        >
          {/*<ModalRoute
          title={t`History`}
          path="history"
          modal={LazyLoad.DashboardHistoryModal}
        />*/}
          <ModalRoute
            title={t`Move`}
            path="move"
            modal={LazyLoad.DashboardMoveModal}
          />
          <ModalRoute
            title={t`Copy`}
            path="copy"
            modal={LazyLoad.DashboardCopyModal}
          />
          <ModalRoute
            title={t`Details`}
            path="details"
            modal={LazyLoad.DashboardDetailsModal}
          />
          <ModalRoute
            title={t`Archive`}
            path="archive"
            modal={LazyLoad.ArchiveDashboardModal}
          />
        </Route>

        <Route
          title={t`Protocols`}
          path="/@:name"
          component={LazyLoad.Creator}
        />


        <Route
          title={t`Activate`}
          path="/activate"
          component={LazyLoad.ActivateAccount}
        />

        {/* ----------- AB Analytics 👇 --------- */}
        <Route
          path="/ab/dashboard/:slug"
          title={t`Dashboard`}
          component={LazyLoad.DashboardApp}
        >
          <ModalRoute path="move" modal={LazyLoad.DashboardMoveModal} />
          <ModalRoute path="copy" modal={LazyLoad.DashboardCopyModal} />
          <ModalRoute path="archive" modal={LazyLoad.ArchiveDashboardModal} />
          <ModalRoute path="details" modal={LazyLoad.DashboardDetailsModal} />
        </Route>
        <Route
          title={t`Creator`}
          path="/ab/@:name"
          component={LazyLoad.Creator}
        />
        <Route
          title={t`Dashboard`}
          path="/ab/@:name/:dashboardName"
          component={LazyLoad.WrapDashboard}
        />
        <Route
          title={t`Dashboard`}
          path="/ab/dashboard/@:name/:dashboardName"
          component={LazyLoad.DashboardApp}
        >
          <ModalRoute
            title={t`Move`}
            path="move"
            modal={LazyLoad.DashboardMoveModal}
          />
          <ModalRoute
            title={t`Copy`}
            path="copy"
            modal={LazyLoad.DashboardCopyModal}
          />
          <ModalRoute
            title={t`Details`}
            path="details"
            modal={LazyLoad.DashboardDetailsModal}
          />
          <ModalRoute
            title={t`Archive`}
            path="archive"
            modal={LazyLoad.ArchiveDashboardModal}
          />
        </Route>
        <Route
          title={t`Public Dashboard`}
          path="/ab/public/dashboard/:uuid"
          component={LazyLoad.PublicDashboard}
        />
        {/*<Route*/}
        {/*  title={t`Create Campaign`}*/}
        {/*  path="/ab/campaign(/:type)"*/}
        {/*  component={LazyLoad.CreateCampaign}*/}
        {/*/>*/}
        <Route
          title={t`Chart`}
          path="/ab/guest/chart/:titleAndId"
          component={LazyLoad.GuestQuestion}
        />
        <Route
          title={t`Chart`}
          path="/ab/public/scene/chart/:titleAndId"
          component={LazyLoad.PublicQuestion}
        />
        <Route
          title={t`Chart`}
          path="/ab/public/widget/chart/:uuid"
          component={LazyLoad.WidgetPublic}
        />
        <Route
          title={t`Chart`}
          path="/ab/public/chart/:titleAndId"
          component={LazyLoad.PublicQuestion}
        />
        <Route
          title={t`Question`}
          path="/ab/chart"
          component={LazyLoad.Question}
        >
          {/* <IndexRoute component={Question} /> */}
          {/* NEW QUESTION FLOW */}
          {/* <Route path="new" title={t`New query`} component={NewQueryOptions} /> */}
          <Route
            title={t`Custom Upload`}
            path="custom-upload"
            component={LazyLoad.CustomUpload}
          />
          <Route
            title={t`Buffet`}
            path=":slug/buffet"
            component={LazyLoad.Question}
          />
          <Route
            title={t`Notebook`}
            path="notebook"
            component={LazyLoad.Question}
          />
          <Route title={t`Detail`} path=":slug" component={LazyLoad.Question} />
          <Route
            title={t`Detail Notebook`}
            path=":slug/notebook"
            component={LazyLoad.Question}
          />
        </Route>
        <Route
          title={t`Growth`}
          path="/ab/games-manage"
          component={LazyLoad.GameList}
        />
        <Route
          title={t`Growth`}
          path="/ab"
          component={LazyLoad.ABProjectContainer}
        >
          <Route path="project/:project(/:menu)" />
        </Route>
        <Route
          title={t`Pricing`}
          path="/ab/pricing"
          component={LazyLoad.FgaPrice}
        />
        <Route
          title={t`Submit Contract`}
          path="/submit/contract/add"
          component={LazyLoad.SubmitContractAddV2}
        />

        <Route
          title={t`Submit Contract`}
          path="/submit/contract"
          component={LazyLoad.SubmitContract}
        />
        <Route
          title={t`Submit Contract`}
          path="/submit/contract/success"
          component={LazyLoad.SubmitContractSuccess}
        />
        <Route
          title={t`Submit Contract`}
          path="/ab/submit/contract/add"
          component={LazyLoad.SubmitContractAddV2}
        />

        <Route
          title={t`Submit Contract`}
          path="/ab/submit/contract"
          component={LazyLoad.SubmitContract}
        />
        <Route
          title={t`Submit Contract`}
          path="/ab/submit/contract/success"
          component={LazyLoad.SubmitContractSuccess}
        />
        <Route title={t`Question`} path="/chart" component={LazyLoad.Question}>
          {/* <IndexRoute component={Question} /> */}
          {/* NEW QUESTION FLOW */}
          {/* <Route path="new" title={t`New query`} component={NewQueryOptions} /> */}
          <Route
            title={t`Custom Upload`}
            path="custom-upload"
            component={LazyLoad.CustomUpload}
          />
          <Route
            title={t`Buffet`}
            path=":slug/buffet"
            component={LazyLoad.Question}
          />
          <Route
            title={t`Notebook`}
            path="notebook"
            component={LazyLoad.Question}
          />
          <Route title={t`Detail`} path=":slug" component={LazyLoad.Question} />
          <Route
            title={t`Detail Notebook`}
            path=":slug/notebook"
            component={LazyLoad.Question}
          />
        </Route>


        <Route
          path="archive"
          title={t`Archive`}
          component={LazyLoad.ArchiveApp}
        />

        <Route path="collection/users" component={IsAdmin}>
          <IndexRoute component={LazyLoad.UserCollectionList} />
        </Route>

        <Route path="collection/:slug" component={LazyLoad.CollectionLanding}>
          <ModalRoute path="move" modal={LazyLoad.MoveCollectionModal} />
          <ModalRoute path="archive" modal={LazyLoad.ArchiveCollectionModal} />
          <ModalRoute path="new_collection" modal={LazyLoad.CollectionCreate} />
          <ModalRoute
            path="new_dashboard"
            modal={LazyLoad.CreateDashboardModal}
          />
          <ModalRoute
            path="permissions"
            modal={LazyLoad.CollectionPermissionsModal}
          />
          {getCollectionTimelineRoutes()}
        </Route>

        <Route path="activity" component={LazyLoad.ActivityApp} />

        <Route
          path="dashboard/:slug"
          title={t`Dashboard`}
          component={LazyLoad.DashboardApp}
        >
          <ModalRoute path="move" modal={LazyLoad.DashboardMoveModal} />
          <ModalRoute path="copy" modal={LazyLoad.DashboardCopyModal} />
          <ModalRoute path="archive" modal={LazyLoad.ArchiveDashboardModal} />
          <ModalRoute path="details" modal={LazyLoad.DashboardDetailsModal} />
        </Route>

        <Route path="/question">
          <IndexRoute component={LazyLoad.QueryBuilder} />
          <Route path="notebook" component={LazyLoad.QueryBuilder} />
          <Route path=":slug" component={LazyLoad.QueryBuilder} />
          <Route path=":slug/notebook" component={LazyLoad.QueryBuilder} />
          <Route path=":slug/:objectId" component={LazyLoad.QueryBuilder} />
        </Route>

        <Route path="/model">
          <IndexRoute component={LazyLoad.QueryBuilder} />
          <Route
            path="new"
            title={t`New Model`}
            component={LazyLoad.NewModelOptions}
          />
          <Route path="notebook" component={LazyLoad.QueryBuilder} />
          <Route path=":slug" component={LazyLoad.QueryBuilder} />
          <Route path=":slug/notebook" component={LazyLoad.QueryBuilder} />
          <Route path=":slug/query" component={LazyLoad.QueryBuilder} />
          <Route path=":slug/metadata" component={LazyLoad.QueryBuilder} />
          <Route path=":slug/:objectId" component={LazyLoad.QueryBuilder} />
          <Route path="query" component={LazyLoad.QueryBuilder} />
          <Route path="metadata" component={LazyLoad.QueryBuilder} />
        </Route>

        <Route path="browse" component={LazyLoad.BrowseApp}>
          <IndexRoute component={LazyLoad.DatabaseBrowser} />
          <Route path=":slug" component={LazyLoad.SchemaBrowser} />
          <Route
            path=":dbId/schema/:schemaName"
            component={LazyLoad.TableBrowser}
          />
        </Route>

        {/* INDIVIDUAL DASHBOARDS */}

        {/*<Route path="/auto/dashboard/*" component={AutomaticDashboardApp} />*/}

        <Route path="/collections">
          <Route path="create" component={LazyLoad.CollectionCreate} />
        </Route>


        {/* PULSE */}
        <Route path="/pulse" title={t`Pulses`}>
          {/* NOTE: legacy route, not linked to in app */}
          <IndexRedirect to="/search" query={{ type: "pulse" }} />
          <Route path="create" component={LazyLoad.PulseEditApp} />
          <Route path=":pulseId">
            <IndexRoute component={LazyLoad.PulseEditApp} />
          </Route>
        </Route>

        {/* ACCOUNT */}
        {getAccountRoutes(store, IsAuthenticated)}

        {/* ADMIN */}
        {getAdminRoutes(store, CanAccessSettings, IsAdmin)}
      </Route>
    </Route>


    {/* DEPRECATED */}
    {/* NOTE: these custom routes are needed because <Redirect> doesn't preserve the hash */}
    <Route
      path="/card/:slug"
      onEnter={({ location, params }, replace) =>
        replace({
          pathname: `/question/${params.slug}`,
          hash: location.hash,
        })
      }
    />
    <Redirect from="/dash/:dashboardId" to="/dashboard/:dashboardId" />
    <Redirect
      from="/collections/permissions"
      to="/admin/permissions/collections"
    />

    {/* MISC */}
    <Route path="/unauthorized" component={Unauthorized} />
    <Route path="/*" component={LazyLoad.NotFoundFallbackPage} />
  </Route>
);
